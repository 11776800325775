import * as React from 'react';
import { useState, Fragment, /*useLayoutEffect*/ } from 'react';
import { Link } from "react-router-dom"
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid'
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import { useNavigate, /*Redirect*/ } from "react-router-dom"


import { withTranslation, useTranslation } from 'react-i18next'

import LogoCabecera from '../assets/images/logos/cavisa.png'

import LogoFondoCabecera from '../assets/images/gotas.jpg'

import config from '../config/config.js'
import BotonPdfTarifa from '../components/BotonPdfTarifa.js';
import BotonPdfDocumento from '../components/BotonPdfDocumento.js';

import ThemeGeroa from '../ThemeGeroa'
import { useStore } from 'react-create-use-store';
import store from './../adderStore.js'



const styles = {
  appBarStyles: {
    backgroundImage: `url(${LogoFondoCabecera})`
  }
}



const Cabecera = ({ t, ...props }) => {

  const { state, actions } = useStore(store)
  const { i18n } = useTranslation()
  const navigate = useNavigate();
  let miMenuGeroa = []
  miMenuGeroa[0] = { texto: "MATERIALES", habilitado: true, salto: "/Materiales" }
  miMenuGeroa[1] = { texto: "PEDIDOS", habilitado: true, salto: "/Pedidos" }
  miMenuGeroa[2] = { texto: "PRODUCTOS", habilitado: true, salto: "/Familias" }
  miMenuGeroa[3] = { texto: t("MenuDrawer.TARIFAS"), habilitado: true, salto: "/Tarifa" }
  miMenuGeroa[4] = { texto: "DOCUMENTOS", habilitado: true, salto: "/Documentos" }
  miMenuGeroa[5] = { texto: "CONTACTO", habilitado: true, salto: "/Contacto" }
  miMenuGeroa[6] = { texto: "ENTRADA2", habilitado: true, salto: "/Carrusel" }



  let miMenuDocumentos = []
  miMenuDocumentos[0] = { texto: t("Medidas de Canalón"), habilitado: true, salto: "/DocMedidas" }
  miMenuDocumentos[1] = { texto: t("Manual de Montaje"), habilitado: true, salto: "/DocPedidos" }


  // Ganchos de los 3 menus
  const [anchorElNav, setAnchorElNav] = useState(null)
  const [anchorElDocumentos, setAnchorElDocumentos] = useState(null);

  // Aperturas de Menus
  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  }
  const handleOpenDocumentosMenu = (event) => {
    setAnchorElDocumentos(event.currentTarget);
  }

  const handleCloseDocumentosMenu = (event, index) => {
    setAnchorElDocumentos(null)

    console.log('se ha pulsado ', event, ' --index = ', index)

    /*
    // Hacer solo si index !== "backdropClick"
    if (index !== "backdropClick") {
      let salto
      // Preparar el salto
      if (state.emailUsuario === '') {
        // Salto futuro
        salto = '/Identificarse'
        actions.cambiarSaltarA(miMenuDocumentos[index].salto)
      }
      else {
        if (index < miMenuDocumentos.length) {
          salto = miMenuDocumentos[index].salto
        }
      }
      if (salto !== null) return navigate(salto)
    }
      */
  }


  // Cierres de los 3 menus
  const handleCloseNavMenu = (event, index) => {
    setAnchorElNav(null)
    if (index !== "backdropClick") {
      let salto
      // Preparar el salto
      if (state.emailUsuario === '') {
        // Salto futuro
        salto = '/Identificarse'
        actions.cambiarSaltarA(miMenuGeroa[index].salto)
      } else {
        salto = miMenuGeroa[index].salto
      }
      return navigate(salto)
    }
  }





  const [anchorEl, setAnchorEl] = React.useState(null);
  //const [value, setValue] = React.useState("menu-item-1");




  return (
    <AppBar position="static"
    >
      <Container
        maxWidth="false" sx={{ height: ThemeGeroa.heightCabecera }}
      >
        <Toolbar disableGutters>
          <Link to="/">
            <img src={LogoCabecera} alt={'Cavisa'} title={'Cavisa'} style={{ width: 'auto', marginTop: 10, height: 80 }} />
          </Link>


          {/* Menú Hamburguesa */}
          <Box sx={{ flexGrow: 1, ml: 5, display: { xs: 'flex', sm: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color='inherit'
            >
              <MenuIcon key={"Icono_1"} />
            </IconButton>
            <Menu
              id="menu-ham"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {miMenuGeroa.map((opcion, index) => (
                
                index !== 3 ?
                  <MenuItem
                    key={"ham0_" + index}
                    onClick={(event) => handleCloseNavMenu(event, index)}
                  >
                    <Typography variant="menuIzquierdacabecera">{opcion.texto}</Typography>
                  </MenuItem>
                  :
                  <Grid sx={{ mt: -2.5, mb: 0.5 }}>
                    <BotonPdfTarifa
                      fichero={config.rutaCarpetaFicheros + 'Tarifa.pdf'}
                      color={"G"}
                    />
                  </Grid>
              ))}
            </Menu>
          </Box>
          {/* Menú Central de Opciones de Texto*/}
          <Box sx={{ flexGrow: 1, ml: 5, display: { xs: 'none', sm: 'flex' } }}>
            {miMenuGeroa.map((opcion, index) => (
              (index === 4 ?
                <Grid key={"fra" + index}>
                  <Tooltip title={"Documentos"}>
                    <Button
                      disabled={!opcion.habilitado}
                      onClick={handleOpenDocumentosMenu}
                      sx={{ my: 4, p: 0, mr: 1 }}
                    >
                      <Typography variant="menuCentralCabecera">{miMenuGeroa[index].texto}</Typography>
                    </Button>
                  </Tooltip>

                  <Menu
                    sx={{ mt: '45px'}}
                    id="menu-bar"
                    anchorEl={anchorElDocumentos}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    open={Boolean(anchorElDocumentos)}
                    onClose={handleCloseDocumentosMenu}
                    PaperProps={{
                      style: {
                        minHeight: 48 * 2.5,
                        width: '25ch',
                      },
                    }}
                  >
                    {miMenuDocumentos.map((opcionInforme, index) => (
                      <>
                        <BotonPdfDocumento
                          fichero={config.rutaCarpetaFicheros + (index === 0 ? 'MEDIDAS_DE_CANALON.pdf' : 'MANUAL_DE_MONTAJE.pdf')}
                          color={"G"}
                          texto={opcionInforme.texto}
                        />
                      </>
                    ))}

                  </Menu>
                </Grid>
                :
                (index !== 3 && index !== 4 ?
                  <Button
                    key={"tex" + index}
                    disabled={!opcion.habilitado}
                    onClick={(event) => handleCloseNavMenu(event, index)}
                    sx={{ my: 2 }}
                  >
                    <Typography variant="menuCentralCabecera">{opcion.texto}</Typography>
                  </Button>
                  :

                  <BotonPdfTarifa
                    fichero={config.rutaCarpetaFicheros + 'Tarifa.pdf'}
                    color={"B"}
                  />


                )

              )





            )
            )}
          </Box>

          {/* Menú del Idioma*/}
          {/*
          <Box sx={{ flexGrow: 0, mr: 1 }}>
            <Tooltip title="Abre Idiomas">
              <IconButton onClick={handleOpenIdiomaMenu} color="inherit" sx={{ p: 0 }}>
                <LanguageIcon />
                <Typography
                  variant="menuIdiomaCabecera"
                  //component="a"
                  //href="/"
                  sx={{
                    mr: 2,
                    ml: 1,
                    fontFamily: 'monospace',
                    fontWeight: 700,
                    color: 'inherit',
                    textDecoration: 'none',
                  }}
                >
                  {state.idioma}
                </Typography>
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-idi"
              anchorEl={anchorElIdioma}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElIdioma)}
              onClose={handleCloseIdiomaMenu}
            >
              {miMenuIdiomas.map((opcionIdioma, index) => (
                <MenuItem
                  key={"idi" + index}
                  onClick={(event) => handleCloseIdiomaMenu(event, index)}
                >
                  {state.idioma === opcionIdioma.abrev ? <Check sx={{ mr: 1 }} /> : <Typography sx={{ ml: 4 }} />}
                  <Typography variant="menuIdiomaCabecera">{opcionIdioma.texto} </Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          */}

          {/* Menú de Usuario
          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Abre Usuario">
              <IconButton onClick={handleOpenUserMenu} color="inherit" sx={{ p: 0 }}>
                <AccountCircle />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {miMenuUsuario.map((opcionUsuario, index) => (
                <Box key={"usu" + index}>
                  {state.emailUsuario !== "" && index === 0 ?
                    <Fragment>
                      <Typography sx={{ ml: 2, mr: 2, mb: 10 }} variant="nombreUsuarioCabecera" noWrap>
                        {state.emailUsuario}
                      </Typography>
                      <Divider sx={{ ml: 0, mr: 0, tp: 10 }} />
                    </Fragment>
                    :
                    <Fragment />
                  }
                  {index === 0 ?
                    <MenuItem
                      key={"us_" + index}
                      component={Link}
                      to={"/Identificarse"}
                      onClick={(event) => handleCloseUserMenu(event, index)}
                      disabled={state.emailUsuario !== ""}
                    >
                      <Typography variant="menuUsuarioCabecera">{opcionUsuario}</Typography>
                    </MenuItem>
                    :
                    <MenuItem
                      key={"us_" + index}
                      onClick={(event) => handleCloseUserMenu(event, index)}
                      disabled={state.emailUsuario === ""}
                    >
                      <Typography variant="menuUsuarioCabecera">{opcionUsuario}</Typography>
                    </MenuItem>
                  }
                </Box>
              ))}
            </Menu>
          </Box>
        */}

        </Toolbar>
      </Container>
      <Box sx={{ minHeight: 70 }} style={styles.appBarStyles}>

      </Box>
    </AppBar>
  );
}

export default withTranslation()(Cabecera)