import React, { Fragment, useState, useEffect } from "react";
import { withTranslation } from 'react-i18next'
import { useStore } from 'react-create-use-store';
import store from './../../adderStore.js'

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import TextareaAutosize from "@mui/material/TextareaAutosize";

import { ThemeProvider, styled } from '@mui/material/styles';

import ThemeGeroa from './../../ThemeGeroa.js'

import { listaMateriales } from './../../apis/api-material.js'

import MensajeCabecera from "../../components/MensajeCabecera.js";
import config from './../../config/config.js'


const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
})(({ theme }) => ({
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
    variants: [
        {
            props: ({ expand }) => !expand,
            style: {
                transform: 'rotate(0deg)',
            },
        },
        {
            props: ({ expand }) => !!expand,
            style: {
                transform: 'rotate(180deg)',
            },
        },
    ],
}));


const Pedidos = ({ t, ...props }) => {
    const { actions } = useStore(store);







    const lista = (

        <Grid sx={{mt:4}}>
            <Typography variant="azul24" style={{ marginLeft: 0 }}>
                {"Condiciones de Venta"}
            </Typography>
            <br />
            <br />
            <Typography variant="negro18" style={{ marginLeft: 0 }}>
                {"1. Pedidos"}
            </Typography>
            <br />
            <br />
            <Typography variant="gris16" style={{ marginLeft: 20 }}>
                {"Los pedidos se formalizarán mediante documento escrito, que podrá realizarse de las siguientes formas:"}
            </Typography>
            <br />
            <Grid sx={{ ml: 8 }}>
                <Typography variant="gris16">
                    {"1. A nuestro Fax 945-265195"}
                </Typography>
                <br />
                <Typography variant="gris16" >
                    {"2. A nuestro email:  "} <a href="mailto:cavisa@cavisa.com">cavisa@cavisa.com</a>
                </Typography>
               
                <br />
                <Typography variant="gris16" >
                    {"3. Por correo postal"}
                </Typography>
                <br />
                <Typography variant="gris16">
                    {"4. Mediante llamada telefónica dirigida a nuestro departamento comercial o a nuestro delegado de zona. Esta forma solo ssrá admitida en aquellas situaciones en las que no se pueda disponer de ninguno de los anteriores procedimientos."}
                </Typography>
            </Grid>
            <br />
            <br />
            <Typography variant="negro18" style={{ marginLeft: 0 }}>
                {"2. Transporte"}
            </Typography>
            <Grid sx={{ ml: 8 }}>
                <Typography variant="gris16">
                    {"Los materiales serán entregados por nuestros vehículos siempre y cuando coincidan con la ruta que se realiza periódicamente."}
                </Typography>
                <br />
                <Typography variant="gris16" >
                    {"Si algún pedido, por urgencia, no puede esperar el envío con nuestros medios, se aplicará el cargo por portes mediante los siguientes criterios:"}
                </Typography>
                <br />
                <Typography variant="gris16" style={{ marginLeft: 25 }}>
                    {"- Pedidos superiores a 900 € (netos, sin Iva), el envio se realizará por cuenta de CAVISA"}
                </Typography>
                <br />
                <Typography variant="gris16" style={{ marginLeft: 25 }}>
                    {"- Pedidos inferiores a 900 € (netos, sin Iva), el envio se realizará por cuenta del CLIENTE"}
                </Typography>
            </Grid>
            <br />
            <br />
            <Typography variant="negro18" style={{ marginLeft: 0 }}>
                {"3. Embalaje"}
            </Typography>
            <Grid sx={{ ml: 8 }}>
                <Typography variant="gris16">
                    {"Todos los materiales que sean enregados con nuestros vehículos, irán debidamente proteguidos, siendo responsabilidad de CAVISA la entrega en perfecto estado y orden de los productos."}
                </Typography>
                <br />
                <Typography variant="gris16">
                    {"Si los materiales viajan por transporte ajeno, iran en palets, cajas o bien con sistemas de protección para evitar desperfectos."}
                </Typography>
                <br />
            </Grid>
            <br />
            <br />
            <Typography variant="negro18" style={{ marginLeft: 0 }}>
                {"4. Reclamaciones"}
            </Typography>
            <Grid sx={{ ml: 8 }}>
                <Typography variant="gris16">
                    {"Los defectos observados a la recepción del pedido se denunciarán bien a la engtrega de los materiales o en un plazo máximo de 7 días. No se aceptarán materiales de dudosa procedencia."}
                </Typography>
                <br />
             
            </Grid>

        </Grid>
    )

    return (
        <ThemeProvider theme={ThemeGeroa}>
            <Container sx={{ mt: 1.5 }}>
                <MensajeCabecera />
                {lista}
                <br />
            </Container>
        </ThemeProvider>
    );
}

export default withTranslation()(Pedidos)