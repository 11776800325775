

const config_cashflow = {
  port: process.env.REACT_APP_MY_PORT,
  //
  rutaApis: "https://cashflow.eus/"+process.env.REACT_APP_MY_PORT,
  rutaFotosAlmacen: "https://cashflow.eus/"+process.env.REACT_APP_RUTA_FOTOS_ALMACEN+"/",
  rutaFotosFamilias: "https://cashflow.eus/"+process.env.REACT_APP_RUTA_FOTOS_FAMILIAS+"/",
  rutaFotosProductos: "https://cashflow.eus/"+process.env.REACT_APP_RUTA_FOTOS_PRODUCTOS+"/",
  rutaFotosMateriales: "https://cashflow.eus/"+process.env.REACT_APP_RUTA_FOTOS_MATERIALES+"/",
  rutaCarpetaFicheros: "https://cashflow.eus/"+process.env.REACT_APP_RUTA_FICHEROS+"/",
  rutaCarpetaFicherosEntregas: "https://cashflow.eus/"+process.env.REACT_APP_RUTA_FICHEROS+"/_pdfReparto/",
  
  //
  jwtSecret: process.env.JWT_SECRET || "1223435688",
  timeToken: 9999,
  //
  passMapas: process.env.REACT_APP_MAPAS,
  notificaciones: true,
  tiempoEntreNotificaciones: 10000 // 10 segundos
}



let config
//
if (process.env.REACT_APP_MY_ENV === "Cashflow") {
  config = config_cashflow
} else if(process.env.REACT_APP_MY_ENV === "Cavisa") {
  //////////////////////////////////////////////////////config = config_cavisa
}


export default config