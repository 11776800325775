import i18n from 'i18next'
import Backend from 'i18next-xhr-backend'
import { initReactI18next } from 'react-i18next'



let idio = localStorage.getItem('idioma')
idio = idio === null ? 'Eus' : idio

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    lng: idio,
    backend: {
      /* translation file path */
      loadPath: './assets_p/i18n/{{ns}}/{{lng}}.json',
     },
    fallbackLng: idio,
    debug: false,
    /* can have multiple namespace, in case you want to divide a huge translation into smaller pieces and load them on demand */
    ns: ['translations'],
    defaultNS: 'translations',
    keySeparator: false,
    interpolation: {
      escapeValue: false,
      formatSeparator: ','
    },
    react: {
      wait: true
    }
  })


export default i18n