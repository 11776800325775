import { createTheme, createStyles } from '@mui/material/styles';
import { green, amber, red } from '@mui/material/colors'
import { responsiveFontSizes } from '@mui/material';

// Subtitulo de Página
const colorTextoH1 = "#026747"
// Subtitulo de Elemento (más Grande de lo normal)
const colorTextosH2 = '#026747'
// Subtitulo 2 y h3 -- Rótulo y capturas o textos y capturas o textos relevantes
const colorSubtitulos2 = '#026747'
const colorTextosH3 = '#616161'
const colorTextosH4 = '#b71c1c'           // Rojo

const colorTextosH5 = '#616161'           // Gris
const colorTextosH6 = '#b71c1c'           // Rojo


const ThemeGeroa = createTheme({
  // Altura de la Cabecera y Pie de la página
  heightCabecera: 70,
  heightFooter: 70,

  palette: {
    primary: {
      main: "#026747",
      //main: '#1769aa',
      light: amber[300],
      dark: green[900],
      contrastText: '#FFF',
    },
    secondary: {
      //main: '#846b31',
      main: "#95C11F",
      dark: green[700],
      contrastText: '#fff',
    },
    rojo: {
      main: red[700],
      contrastText: '#fff',
    },
    verde: {
      main: "#026747",
      contrastText: '#fff',
    },
    naranja: {
      main: '#e65100',
      contrastText: '#fff',
    },
    azul: {
      main: '#1565c0',
      contrastText: '#fff',
    },
    purpura: {
      main: '#9C1E1E',
      contrastText: '#fff',
    },
    negro: {
      main: '#000'
    },
    gris: {
      main: '#aaaaaa'
    },
    blanco: {
      main: '#FFF'
    },
  },

  typography: {
    textAlign: "left",
    color: "red",
  //},
  //typography: {
    /*
    h1
    h2
    h3
    h4
    h5
    h6
    subtitle1
    subtitle2
    body1
    body2
    button
    caption
    overline
    
    */

    /////////////////////////////
    // Cabecera
    nombreEmpresaCabecera: {
      fontSize: 30,
      fontWeight: 600
    },
    nombreUsuarioCabecera: {
      color: 'red',
      fontSize: 11,
      fontWeight: 500
    },
    // Menu Superior Estirado
    menuCentralCabecera: {
      fontSize: 15,
      fontWeight: 600,
      color: 'white'
    },
     // Menu Superior Estirado
     menuCentralCabeceraVerde11111: {
      fontSize: 17,
      fontWeight: 700,
      color: '#bf360c'
    },
    menuIzquierdaCabecera: {
      fontSize: 13,
      fontWeight: 500
    },
    menuIdiomaCabecera: {
      fontSize: 13,
      fontWeight: 500
    },
    menuUsuarioCabecera: {
      fontSize: 13,
      fontWeight: 500
    },

    textosCapturas: {
      fontSize: 13,
      fontWeight: 500,
      color: '#1565c0',
      //color: '#616161'
    },
    textosCapturasVerdes: {
      color: 'green',
      fontWeight: 500,
      fontSize: 13
    },
    // Azul
    azul24: {
      color: '#1565c0',
      fontWeight: 600,
      fontSize: 24
    },
    azul18: {
      color: '#1565c0',
      fontWeight: 600,
      fontSize: 18
    },
    azul16: {
      color: '#1565c0',
      fontWeight: 500,
      fontSize: 16
    },
    azul15: {
      color: '#1565c0',
      fontWeight: 500,
      fontSize: 15
    },
    azul14: {
      color: '#1565c0',
      fontWeight: 500,
      fontSize: 14
    },
    azul13: {
      color: '#1565c0',
      fontWeight: 500,
      fontSize: 13
    },
    azul12: {
      color: '#1565c0',
      fontWeight: 500,
      fontSize: 12
    },
    // Verde
    verde30: {
      color: "#026747",
      fontWeight: 600,
      fontSize: 30,
    },
    // Verde
    verde20: {
      color: "#026747",
      fontWeight: 600,
      fontSize: 20,
    },
    verde18: {
      color: 'green',
      fontWeight: 500,
      fontSize: 18
    },
    verde18N: {
      color: "green",
      fontWeight: 600,
      fontSize: 18
    },
    verde16: {
      color: "green",
      fontWeight: 500,
      fontSize: 16
    },
    verde15: {
      color: 'green',
      fontWeight: 500,
      fontSize: 15
    },
    verde14: {
      color: "green",
      fontWeight: 500,
      fontSize: 14
    },
    verde13: {
      color: 'green',
      fontWeight: 500,
      fontSize: 13
    },
    verde13N: {
      color: "green",
      fontWeight: 600,
      fontSize: 13
    },
    verde12: {
      color: 'green',
      fontWeight: 500,
      fontSize: 12
    },
    // Gris
    gris19: {
      color: '#616161',
      fontWeight: 500,
      fontSize: 19
    },
    gris18: {
      color: '#616161',
      fontWeight: 500,
      fontSize: 18
    },
    gris16: {
      color: '#616161',
      fontWeight: 500,
      fontSize: 16
    },
    gris15: {
      color: '#616161',
      fontWeight: 500,
      fontSize: 15
    },
    gris14: {
      color: '#616161',
      fontWeight: 500,
      fontSize: 14
    },
    gris13: {
      color: '#616161',
      fontWeight: 500,
      fontSize: 13
    },
    gris13N: {
      color: '#515151',
      fontWeight: 600,
      fontSize: 13
    },
    gris12: {
      color: '#616161',
      fontWeight: 500,
      fontSize: 12
    },
    gris11: {
      color: '#616161',
      fontWeight: 500,
      fontSize: 12
    },
    // Naranja
    naranja19: {
      color: "orange",
      fontWeight: 500,
      fontSize: 19
    },
    naranja18: {
      color: "#ff6333",
      fontWeight: 600,
      fontSize: 18
    },
    naranja15: {
      color: "#ff6333",
      fontWeight: 500,
      fontSize: 15
    },
    naranja14: {
      color: "#ff6333",
      fontWeight: 500,
      fontSize: 14
    },
    naranja12: {
      color: "#ff6333",
      fontWeight: 500,
      fontSize: 12
    },
    naranja11: {
      color: "#ff6333",
      fontWeight: 500,
      fontSize: 11
    },
    rojo18: {
      color: "red",
      fontWeight: 500,
      fontSize: 18
    },
    rojo16: {
      color: "red",
      fontWeight: 500,
      fontSize: 16
    },
    rojo15: {
      color: "red",
      fontWeight: 500,
      fontSize: 15
    },
    rojo14: {
      color: "red",
      fontWeight: 500,
      fontSize: 14
    },
    rojo13: {
      color: "red",
      fontWeight: 500,
      fontSize: 13
    },
    rojo13N: {
      color: "red",
      fontWeight: 600,
      fontSize: 13
    },
    rojo12: {
      color: "red",
      fontWeight: 500,
      fontSize: 12
    },
    rojo10: {
      color: "red",
      fontWeight: 500,
      fontSize: 10
    },
    blanco16: {
      color: "white",
      fontWeight: 500,
      fontSize: 16
    },
    negro18: {
      color: "black",
      fontWeight: 500,
      fontSize: 18
    },

    // Titulos de Página
    h1: {
      color: colorTextoH1,
      fontSize: 30,
      fontWeight: 600
    },


    // Titulos más grandes que captura ordinaria
    h2: {
      color: colorTextosH2,
      fontSize: 17,
      fontWeight: 600
    },



    // Títulos de capturas, capturas y capturas destacadas
    subtitle2: {
      color: colorSubtitulos2,
      fontSize: 13,
      fontWeight: 600,
      fontStyle: 'italic',
    },
    h3: {
      color: colorTextosH3,
      fontSize: 13,
      fontWeight: 600
    },
    h4: {
      color: colorTextosH4,
      fontSize: 13,
      fontWeight: 500
    },
    h5: {
      color: colorTextosH5,
      fontSize: 11,
      fontWeight: 500
    },
    h6: {
      color: colorTextosH6,
      fontSize: 11,
      fontWeight: 500
    },



    subtitle1: {
      color: '#1565c0',
      fontSize: 16,
      fontWeight: 500
    },





    body1: {
      color: '#000000',
      fontSize: 16,
      fontWeight: 500
    },
    body2: {
      color: '#000000',
      fontSize: 12,
      fontWeight: 500
    },
  },




  overrides: {
    MuiFormControlLabel: createStyles({
      label: {
        fontSize: "11px",

      }
    })
  }






});



export default responsiveFontSizes(ThemeGeroa)




/* Tamaños de Pantalla

    xs, extra-small: 0px
    sm, small: 600px
    md, medium: 900px
    lg, large: 1200px
    xl, extra-large: 1536px






*/