import { createStore } from 'react-create-use-store';

// initial state
const state = {
  idioma: 'Es',
  emailUsuario: '',
  codCliente: '',
  saltarA: '',
  familias: [],
  familia: {
    codigo: '',
    nombre: ''
  },
};

// list of action functions
const actions = {
  cambiarIdioma(id) {
    store.setState(old => ({ ...old, idioma: id }));
  },
  cambiarEmailUsuario(us) {
    store.setState(old => ({ ...old, emailUsuario: us }));
  },
  cambiarCodCliente(cc) {
    store.setState(old => ({ ...old, codCliente: cc }));
  },
  cambiarSaltarA(sa) {
    store.setState(old => ({ ...old, saltarA: sa }));
  },
  cambiarFamilias(fa) {
    store.setState(old => ({ ...old, familias: fa }));
  },
  cambiarMensaje(me) {
    store.setState(old => ({ ...old, mensaje: me }));
  },
  ponerFamilia(objFam) {
    store.setState(old => ({...old, familia: objFam}));
  }

};

// create and export the store
const store = createStore({ state, actions });
export default store;