import config from './../config/config.js'


const rutaApi = config.rutaApis+'/familias'



const listaFamilias = () => {
  return fetch(rutaApi+'/listaFamilias', {
    method: 'GET'
  }).then((response) => {
    return response.json()
  }).catch((err) => {
    console.log(err)
  })
}





const listaSubFamilias = (params) => {
  return fetch(rutaApi+'/listaSubFamilias/' + params.familia, {
    method: 'GET'
  }).then((response) => {
    return response.json()
  }).catch((err) => {
    console.log(err)
  })
}
export {
  listaFamilias,
  listaSubFamilias
}