import React, { Fragment, useState, useEffect } from "react";
import { withTranslation } from 'react-i18next'
import { useStore } from 'react-create-use-store';
import store from './../../adderStore.js'

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Box from "@mui/material/Box";
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { ThemeProvider } from '@mui/material/styles';

import PanelProducto from "./PanelProducto.js";

import ThemeGeroa from './../../ThemeGeroa.js'

import { listaSubFamilias } from "../../apis/api-familia.js";
//import auth from './../../apis/auth-helper.js'

import MensajeCabecera from "../../components/MensajeCabecera.js";


const SubFamilias = ({ t, ...props }) => {
    //const jwt = auth.isAuthenticated()
    const { state, actions } = useStore(store);
    const [subFamilias, setSubFamilias] = useState([])

    const objFamilia = {
        familia: state.familia.codigo,
        nombre: state.familia.nombre,
    }


    useEffect(() => {
        // Carga de datos Inicial --> despues de la carga renderiza
        async function loadSubFamilias() {
            // Busca los productos en funcion del filtro:
            await listaSubFamilias({ familia: objFamilia.familia }).then((data) => {
                console.log("data", data)
                if (data.error) {
                    console.log(data.error)
                }
                else {
                    // Actualizar Tabla Productos. //
                    data.dataP.forEach(p => {
                        // Añadir Tabla Medidas en cada Producto. //
                        p.medidas = data.dataM.filter((m) => m.MedPro === p.ProCod)
                    });
                    // Actualizar Tabla SubFamilias. //
                    data.dataS.forEach(s => {
                        // Añadir Tabla Productos en cada SubFamilia. //
                        s.productos = data.dataP.filter((p) => p.ProSub === s.SubCod)
                    });
                    //console.log('aaaaa = ', data.dataS)

                    // Guardar las Subfamilias. Fuerza renderizado. //
                    setSubFamilias([...data.dataS])
                }
            })
        }

        actions.cambiarMensaje('cargando productos')
        loadSubFamilias()
        actions.cambiarMensaje('')

        // array de dependencias: actions no cambia nunca //        
    }, [actions, objFamilia.familia])



    const tituloYFiltros = (
        <div>
            <Box sx={{ display: 'flex' }} height="50px">
                <Typography variant="verde30">
                    {objFamilia.nombre}
                </Typography>
            </Box>
        </div>
    )


    const lista = (
        subFamilias.length > 0 ?
            <Grid sx={{ mt: 0 }}>
                {subFamilias.map((linea, i) => (
                    <Grid item key={"fa" + linea.FamCod + i}>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1-content"
                                id="panel1-header"
                            >
                                <Typography variant="verde20">
                                    {linea.SubDes}
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                {linea.productos.map(pro => (
                                    <>
                                        <Grid item key={"fa" + pro.ProCod}>
                                            <PanelProducto
                                                key={"pfp" + pro.ProCod}
                                                producto={pro}
                                            //arrProductos={arrProductos}
                                            //recargar={loadProductosIniciales}
                                            />
                                        </Grid>
                                    </>
                                ))}
                            </AccordionDetails>
                        </Accordion>
                    </Grid>
                ))}
            </Grid >
            :
            <Fragment />
    )


    return (
        <ThemeProvider theme={ThemeGeroa}>
            <Container sx={{ mt: 1.5 }}>
                {tituloYFiltros}
                <MensajeCabecera />
                {lista}
                <br />
            </Container>
        </ThemeProvider>
    );
}

export default withTranslation()(SubFamilias)