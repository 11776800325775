//import liraries
import React from 'react';
//import Button from "@mui/material/Button";
import { ThemeProvider } from '@mui/material/styles';
import { withTranslation } from 'react-i18next'
import ThemeGeroa from '../ThemeGeroa';

import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';

import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'


// create a component
const BotonPdfDocumento = ({ t, ...props }) => {

   // Establece los clolores
    let c1 = props.color === "B" ? ThemeGeroa.palette.blanco.main : ThemeGeroa.palette.gris.main
    let c2 = props.color === "B" ? "blanco16" : "gris16"


    return (
        <ThemeProvider theme={ThemeGeroa}>
            <Grid sx={{ ml: 1, mt: 2.5 }}>
                <a
                    style= {{textDecoration: 'none'}}
                    href={props.fichero}
                    target="_blank"
                    rel="noopener noreferrer"
                    download
                >
                    <PictureAsPdfIcon style={{ color:c1, fontSize: 30 }} />
                    <Grid sx={{ ml: 5, mt: -3.8, mr:1 }}>
                        <Typography variant={c2}>
                            {props.texto}
                        </Typography>
                    </Grid>
                </a>

            </Grid>
        </ThemeProvider>
    );
};

export default withTranslation()(BotonPdfDocumento)