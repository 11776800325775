import React from 'react'
import { Route, Routes } from 'react-router-dom'

import Entrada from '../views/Entrada.js'
import Identificarse from "../views/Identificarse.js";
import Materiales from '../views/Materiales/Materiales.js'
import Pedidos from '../views/Pedidos/Pedidos.js'
import Contacto from '../views/Contacto/Contacto.js'
import Familias from '../views/Familias/Familias.js'
import SubFamilias from '../views/SubFamilias/SubFamilias.js'

import Carrusel from '../views/Carrusel/Carrusel.js'


export default function Rutas(props) {
  return (
    <div>
    <Routes>
      <Route path="/" element={<Entrada/>} />
      <Route path="/Identificarse" element={<Identificarse/>} />
      <Route path="/Materiales" element={<Materiales/>} />
      <Route path="/Pedidos" element={<Pedidos/>} />
      <Route path="/Familias" element={<Familias/>} />
      <Route path="/Contacto" element={<Contacto/>} />
      <Route path="/SubFamilias" element={<SubFamilias/>} />
      <Route path="/Carrusel" element={<Carrusel/>} />
    </Routes>
</div>
  );
};

