import React, { Suspense, useEffect, useState, /*useLayoutEffect*/ } from "react";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from '@mui/material/styles';

// Obligatorio: No comentar. //
import i18n from './i18n';
import { withTranslation } from 'react-i18next'

//
import Rutas from "./routes/rutas.js";
//
import ThemeGeroa from './ThemeGeroa.js'
import Cabecera from './core/cabecera.js'
//import Footer from './core/footer.js'


import { listaFamilias } from "./apis/api-familia.js";



import { useStore } from 'react-create-use-store';
import store from './adderStore.js'

let pruebas = true

function App({ t }) {

  const [carga, setCarga] = useState(true)
  const { state, actions } = useStore(store);

  /////////////////////////////////////////////////
  // Variables Globales y Funciones para manejarlas
  /////////////////////////////////////////////////
  // leer el Idioma último usado
  let idio = localStorage.getItem('idioma')
  idio = idio === null ? 'Es' : idio
  // Temporalmente: Idioma sólo en Español. //
  idio = 'Es'




  /////////////////////////////////
  /////////////////////////////////
  // TODAS LAS FAMILIAS
  /////////////////////////////////
  /////////////////////////////////
  async function loadFamilias() {
    await listaFamilias().then((dataF) => {
      if (dataF.error) {
        console.log(dataF.error)
      }
      else {
        actions.cambiarFamilias(dataF)
        //
      }
    })
  }


  useEffect(() => {
    if (carga) {
      setCarga(false)
      // Si el idioma actual es distinto del almacenado. //
      if (idio !== state.idioma) {
        actions.cambiarIdioma(idio)
      }
      //
      if (pruebas) {
        let cli = "430000001"
        actions.cambiarIdioma(idio)
        actions.cambiarEmailUsuario("armentia111@geroa.com")
        actions.cambiarCodCliente(cli)
        //
      }
      loadFamilias()
    }
  }, [])


  return (
    <Suspense fallback={null}>
      <ThemeProvider theme={ThemeGeroa}>
        <BrowserRouter>
          <Cabecera />
          <Rutas />
        </BrowserRouter>
      </ThemeProvider>
    </Suspense>
  )
}

export default withTranslation()(App)