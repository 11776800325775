import React from "react";
import "./Carrusel.css";

import config from './../../config/config.js'


console.clear();

const slides = [
  "almacen1.jpg",
  "almacen2.jpg",
  "almacen3.jpg",
  "almacen4.jpg",
  "almacen5.jpg"
];

function useTilt(active) {
  const ref = React.useRef(null);

  React.useEffect(() => {
    if (!ref.current || !active) {
      return;
    }

    const state = {
      rect: undefined,
      mouseX: undefined,
      mouseY: undefined
    };

    let el = ref.current;

    const handleMouseMove = (e) => {
      if (!el) {
        return;
      }
      if (!state.rect) {
        state.rect = el.getBoundingClientRect();
      }
      state.mouseX = e.clientX;
      state.mouseY = e.clientY;
      const px = (state.mouseX - state.rect.left) / state.rect.width;
      const py = (state.mouseY - state.rect.top) / state.rect.height;

      el.style.setProperty("--px", px);
      el.style.setProperty("--py", py);
    };

    el.addEventListener("mousemove", handleMouseMove);

    return () => {
      el.removeEventListener("mousemove", handleMouseMove);
    };
  }, [active]);

  return ref;
}

const initialState = {
  slideIndex: 0
};

const slidesReducer = (state, event) => {
  if (event.type === "NEXT") {
    return {
      ...state,
      slideIndex: (state.slideIndex + 1) % slides.length
    };
  }
  if (event.type === "PREV") {
    return {
      ...state,
      slideIndex:
        state.slideIndex === 0 ? slides.length - 1 : state.slideIndex - 1
    };
  }
};

function Slide({ imagen, offset }) {
  const active = offset === 0 ? true : null;
  const ref = useTilt(active);

  const urlImagen = config.rutaFotosAlmacen + imagen

  return (
    <div
      ref={ref}
      className="slide"
      data-active={active}
      style={{
        "--offset": offset,
        "--dir": offset === 0 ? 0 : offset > 0 ? 1 : -1
      }}
    >
      <div
        className="slideBackground"
        style={{
          backgroundImage: `url('${urlImagen}')`
        }}
      />
      <div
        className="slideContent"
        style={{
          backgroundImage: `url('${urlImagen}')`
        }}
      >
      </div>
    </div>
  );
}

function Carrusel2() {
  const [state, dispatch] = React.useReducer(slidesReducer, initialState);

  return (
    <div className="slides">
      <button onClick={() => dispatch({ type: "PREV" })}>‹</button>
      {[...slides, ...slides, ...slides].map((imagen, i) => {
        let offset = slides.length + (state.slideIndex - i);
        return <Slide imagen={imagen} offset={offset} key={i} />;
      })}
      <button onClick={() => dispatch({ type: "NEXT" })}>›</button>
    </div>
  );
}

//const elApp = document.getElementById("app");
//ReactDOM.render(<App />, elApp);
export default Carrusel2;
