import React, { useState } from 'react';
import { withTranslation } from 'react-i18next'

import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { ThemeProvider } from '@mui/material/styles';

import ThemeGeroa from './../../ThemeGeroa.js'

import AmpliaImagen from "../../components/AmpliaImagen.js";

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper'

import config from './../../config/config.js'





const PanelProducto = ({ t, ...props }) => {
    const [producto] = useState(props.producto)
    const [foto] = useState(config.rutaFotosProductos + producto.ProFot + '.jpg?f=' + (new Date().getTime()))



    return (
        <ThemeProvider theme={ThemeGeroa}>

            <Grid container style={{
                display: 'flex',
                flexDirection: 'row',
            }}>
                <Grid container xs={9}>

                    <Grid item sx={{}}>
                        <AmpliaImagen
                            prod={producto.ProCod}
                            foto={foto}
                            producto={producto.ProNom}
                        />
                    </Grid>
                    <Grid item>
                        <Typography variant="azul18">
                            {producto.ProNom}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container xs={3} style={{
                    display: 'flex',
                    justifyContent: "flex-end"
                }}>
                    <Grid item>
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 200 }} size="small" aria-label="a dense table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell><Typography variant="azul13">Dimensiones</Typography></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {producto.medidas.map((row) => (
                                        <TableRow
                                            key={row.name}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >

                                            <TableCell align="left">{row.MedDes}</TableCell>

                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
            </Grid>
            <br />
            <Divider />
        </ThemeProvider >
    )

}

export default withTranslation()(PanelProducto)