import React, { Fragment, useState, useEffect } from "react";
import { withTranslation } from 'react-i18next'
import { useStore } from 'react-create-use-store';
import store from './../../adderStore.js'

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import { ThemeProvider, styled } from '@mui/material/styles';

import ThemeGeroa from './../../ThemeGeroa.js'

import { listaMateriales } from './../../apis/api-material.js'

import MensajeCabecera from "../../components/MensajeCabecera.js";
import config from './../../config/config.js'


const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
})(({ theme }) => ({
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
    variants: [
        {
            props: ({ expand }) => !expand,
            style: {
                transform: 'rotate(0deg)',
            },
        },
        {
            props: ({ expand }) => !!expand,
            style: {
                transform: 'rotate(180deg)',
            },
        },
    ],
}));


const Materiales = ({ t, ...props }) => {
    const { actions } = useStore(store);
    const [materiales, setMateriales] = useState([])
    const [expanded, setExpanded] = useState({});


    useEffect(() => {
        // Incluir la funcion en el useEffect para quitarla del array de dependencias. //
        async function cargaMateriales() {
            await listaMateriales().then((dataM) => {
                //console.log("DataM", dataM)
                // Fuerza Renderizado
                setMateriales([...dataM])
            })
        }

        actions.cambiarMensaje('cargando materiales')
        cargaMateriales()
        actions.cambiarMensaje('')

        // array de dependencias: actions no cambia nunca //        
    }, [actions])


    const handleExpandClick = (codigo) => {
        expanded[codigo] = expanded[codigo] === undefined ? true : !expanded[codigo]
        setExpanded({ ...expanded });
    };


    const lista = (
        materiales.length > 0 ?
            <Grid container>
                {materiales.map(material => (
                    <Grid item key={"fa" + material.MatCod} /*sx={{ width: 1 / 3 }}*/>
                        <Card sx={{ marginTop: 5, marginRight: 3, width: 260, /*minWidth: 260, maxWidth: 300, maxHeight: 400*/ }}>
                            <Typography variant="azul18" style={{ marginLeft: 5 }}>
                                {material.MatNom}
                            </Typography>
                            <CardMedia
                                component="img"
                                height="200"
                                image={config.rutaFotosMateriales + material.MatFot + '?f=' + (new Date().getTime())}
                                alt={material.MatNom}
                            />
                            <CardActions disableSpacing>
                                <Typography variant="gris12">
                                    {"CARACTERÍSTICAS:"}
                                </Typography>
                                <ExpandMore
                                    expand={expanded[material.MatCod]}
                                    onClick={() => { handleExpandClick(material.MatCod) }}
                                    aria-expanded={expanded}
                                    aria-label="show more"
                                >
                                    <ExpandMoreIcon />
                                </ExpandMore>
                            </CardActions>
                            <Collapse in={expanded[material.MatCod]} timeout="auto" unmountOnExit>
                                <CardContent>
                                    <Typography variant="gris12">
                                        {material.MatDes}
                                    </Typography>
                                </CardContent>
                            </Collapse>
                        </Card>
                    </Grid>
                ))
                }
            </Grid >
            :
            <Fragment />
    )

    return (
        <ThemeProvider theme={ThemeGeroa}>
            <Container sx={{ mt: 1.5 }}>
                <MensajeCabecera />
                {lista}
                <br />
            </Container>
        </ThemeProvider>
    );
}

export default withTranslation()(Materiales)