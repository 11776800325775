import config from './../config/config.js'

const rutaApi = config.rutaApis + '/materiales'


const listaMateriales = () => {
  return fetch(rutaApi+'/listaMateriales', {
    method: 'GET'
  }).then((response) => {
    return response.json()
  }).catch((err) => {
    console.log(err)
  })
}



export {
  listaMateriales

}