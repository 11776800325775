import React, { Fragment, useState, useEffect } from "react";
import { withTranslation } from 'react-i18next'
import { useStore } from 'react-create-use-store';
import store from './../../adderStore.js'

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import TextareaAutosize from "@mui/material/TextareaAutosize";
import Divider from '@mui/material/Divider'

import { ThemeProvider, styled } from '@mui/material/styles';

import ThemeGeroa from './../../ThemeGeroa.js'

import { listaMateriales } from './../../apis/api-material.js'
import { APIProvider, Map, AdvancedMarker, Pin, InfoWindow, MapCameraChangedEvent } from '@vis.gl/react-google-maps';

import MensajeCabecera from "../../components/MensajeCabecera.js";
import config from './../../config/config.js'




const Contacto = ({ t, ...props }) => {
    const { actions } = useStore(store);

    const [estado, setEstado] = useState(
        {
            open: false

        }
    )

    let posCavisa = { lat: 42.85917, lng: -2.65662 }        // Cavisa



    function colorDelPunto(p) {
        let color
        color = "#ff6333"
        return color
    }

    function colorDelGlyph(p) {
        let color
        color = "white"
        return color
    }

    function colorDelBorde(p) {
        let color
        color = "orange"
        return color
    }



    const puntoExistenteOpen = (event) => {
        setEstado({ ...estado, open: true })
    }

    const puntoExistenteClose = (event) => {
        setEstado({ ...estado, open: false })
    }


    const lista = (

        <Grid container style={{
            display: 'flex',
            flexDirection: 'row',
        }} sx={{ mt: 6 }}>
            <Grid Item xs={3}>
                <Typography variant="verde20">
                    {"Cavisa"}
                </Typography>
                <br />
                <br />
                <Typography variant="gris16">
                    {"C / Mondragon 2"}
                </Typography>
                <br />
                <Typography variant="gris16">
                    {"01013 - Vitoria-Gasteiz"}
                </Typography>
                <br />
                <Typography variant="gris16">
                    {"Alava"}
                </Typography>
                <br />
                <br />
                <Typography variant="gris16">
                    {"Teléfono: 945-255711"}
                </Typography>
                <br />
                <Typography variant="gris16">
                    {"Fax: 945-265195"}
                </Typography>
                <br />
                <Typography variant="gris16">
                    {"Email:"} <a href="mailto:cavisa@cavisa.com">cavisa@cavisa.com</a>
                </Typography>
                <br />


            </Grid>
            <Grid xs={9} sx={{ minHeight: 600 }}>
                <Fragment>
                    <APIProvider apiKey={config.passMapas}>
                        <Map
                            //onClick={nuevoPunto}
                            zoom={12}
                            //center={posEneek}
                            center={posCavisa}
                            mapId={'MiMapa'}
                            loading={"async"}
                        >

                            <AdvancedMarker
                                key={"k2_" + "Cavisa"}
                                position={posCavisa}
                                draggable={false}
                                onClick={puntoExistenteOpen}
                            //onDragEnd={soltamosPunto(i)}
                            >
                                {/*<PuntoMapa punto={p} />*/}

                                <Pin
                                    background={colorDelPunto(posCavisa)}
                                    borderColor={colorDelBorde(posCavisa)}
                                    glyphColor={colorDelGlyph(posCavisa)}
                                >
                                </Pin>
                            </AdvancedMarker>
                            {estado.open && <InfoWindow
                                key={"k3_" + "Cavisa"}
                                position={posCavisa}
                                minWidth={400}
                                onCloseClick={puntoExistenteClose}
                            >

                                <Grid>
                                    <Typography variant="verde16">
                                        {"Cubiertas y Accesorios Vitoria"}
                                    </Typography>
                                    <Divider />
                                    <br />
                                    <Typography variant="gris13">
                                        {"C / Mondragon 2"}
                                    </Typography>
                                    <br />
                                    <Typography variant="gris13">
                                        {"01013 - Vitoria-Gasteiz"}
                                    </Typography>
                                    <br />
                                    <Typography variant="gris13">
                                        {"Alava"}
                                    </Typography>

                                </Grid>
                            </InfoWindow>}

                        </Map>
                    </APIProvider>
                </Fragment>
            </Grid>
        </Grid>
    )



    const lista1 = (

        <Grid container style={{
            display: 'flex',
            flexDirection: 'row',
        }}>
            <Grid Item>
                aaaaa
            </Grid>
            <Grid container>


                <APIProvider apiKey={config.passMapas} onLoad={() => console.log('Maps API has loaded.')}>
                    <h1>Hello, world!</h1>

                    <Map
                        //onClick={nuevoPunto}
                        zoom={9}
                        //center={posEneek}
                        center={posCavisa}
                        mapId={'MiMapa'}
                        loading={"async"}
                    >
                        <AdvancedMarker
                            key={"k2_" + "cavisa"}
                            position={posCavisa}
                            draggable={true}
                        //onClick={puntoExistenteOpen(i)}
                        //onDragEnd={soltamosPunto(i)}
                        >
                            {/*<PuntoMapa punto={p} />*/}

                            <Pin
                                background={colorDelPunto(posCavisa)}
                                borderColor={colorDelBorde(posCavisa)}
                                glyphColor={colorDelGlyph(posCavisa)}
                            >
                            </Pin>
                        </AdvancedMarker>

                    </Map>

                </APIProvider>
            </Grid>

        </Grid>
    )

    return (
        <ThemeProvider theme={ThemeGeroa}>
            <Container sx={{ mt: 1.5 }}>
                <MensajeCabecera />
                {lista}
                <br />
            </Container>
        </ThemeProvider>
    );
}

export default withTranslation()(Contacto)
