import React, { Fragment, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { withTranslation } from 'react-i18next'
import { useStore } from 'react-create-use-store';
import store from './../../adderStore.js'

import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardActions from '@mui/material/CardActions';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { ThemeProvider } from '@mui/material/styles';

import ThemeGeroa from './../../ThemeGeroa.js'

import { listaFamilias } from "../../apis/api-familia.js";

import MensajeCabecera from "../../components/MensajeCabecera.js";

import config from './../../config/config.js'


const Familias = ({ t, ...props }) => {
    //const jwt = auth.isAuthenticated()
    //const { state, actions } = useStore(store);
    const { actions } = useStore(store);
    const [familias, setFamilias] = useState([])


    useEffect(() => {
        // Incluir la funcion en el useEffect para quitarla del array de dependencias. //
        async function loadFamilias() {
            // Busca los productos en funcion del filtro:
            await listaFamilias().then((data) => {
                if (data.error) {
                    console.log(data.error)
                }
                else {
                    // Fuerza Renderizado
                    setFamilias([...data])
                }
            })
        }

        //state.mensaje = "Cargando familias"
        actions.cambiarMensaje('cargando materiales')
        loadFamilias()
        //state.mensaje = ""
        actions.cambiarMensaje('')

        // array de dependencias: actions no cambia nunca //        
    }, [actions])


    const clickFamilia = (familia) => {
        console.log("Familia: ", familia)
        actions.ponerFamilia({ codigo: familia.FamCod, nombre: familia.FamNom })
    }


    const lista = (
        familias.length > 0 ?
            <Grid container>
                {familias.map(familia => (
                    <Grid item key={"fa" + familia.FamCod} /*sx={{ width: 1 / 3 }}*/>
                        <Card sx={{ marginTop: 5, marginRight: 3, maxWidth: 310, maxHeight: 450 }}>
                            <Typography variant="azul18" style={{ marginLeft: 2 }}>
                                {familia.FamNom}
                            </Typography>
                            <CardMedia
                                component="img"
                                height="194"
                                image={config.rutaFotosFamilias + familia.FamFot + '.jpg?f=' + (new Date().getTime())}
                                alt={familia.FamNom}
                            />
                            <CardActions disableSpacing>
                                <Button
                                    size="small"
                                    component={Link}
                                    to={"/Subfamilias"}
                                    onClick={() => { clickFamilia(familia) }}
                                >Ver Productos</Button>
                            </CardActions>
                        </Card>
                    </Grid>
                ))}
            </Grid >
            :
            <Fragment />
    )

    return (
        <ThemeProvider theme={ThemeGeroa}>
            <Container sx={{ mt: 1.5 }}>
                <MensajeCabecera />
                {lista}
                <br />
            </Container>
        </ThemeProvider>
    );
}

export default withTranslation()(Familias)